import React from 'react'
import { HashLink } from 'react-router-hash-link';
import { createScope, map, transformProxies } from './helpers'
import ButtonFullEffects from '../../components/webflow/ButtonFullEffects'
import ScrollSectionButton from '../../components/webflow/ScrollSectionButton'
import ScrollTopButton from '../../components/ScrollTopButton';
import SiteHeader from '../../components/SiteHeader'
import SiteFooter from '../../components/SiteFooter'
import NutshellContent from '../../components/NutshellContent'
// import TypeAnimation from 'react-type-animation'
import ReactTextTransition, { presets } from "react-text-transition";
// https://www.npmjs.com/package/react-text-transition
import Collapsible from 'react-collapsible';

const scripts = [
  { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=61e48a462079c356c6452042").then(body => body.text()), isAsync: false },
  { loading: fetch("js/webflow.js").then(body => body.text()), isAsync: false },
]

const rollingTexts = [
  'Growing together to fight climate change and reinvent our world',
  'Transforming existing industries and infrastructure through sustainable technologies and supply chain management',
  'Generating positive social impact through circular economy, inclusion, technology and information access'
];

let Controller

class IndexView extends React.Component {

  state = {
    textIndex: 0,
    collapsiblesStates: [false, false, false, false, false],
    noneOpen: true,
  };

  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../Index')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code === 'MODULE_NOT_FOUND') {
        Controller = IndexView

        return Controller
      }

      throw e
    }
  }

  // toggleOpen(collapsibleIndex) {
  //   let {collapsiblesStates, noneOpen} = this.state;
  //   let newState = !collapsiblesStates[collapsibleIndex];
  //   collapsiblesStates[collapsibleIndex] = newState;
  //   if (newState===true) { noneOpen = false; } else {
  //     noneOpen = collapsiblesStates.reduce((prev, cur) => !prev && !cur, false);
  //   }
  //   this.setState({collapsiblesStates:collapsiblesStates, noneOpen: noneOpen});
  // }

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '6242288191da2b50c6937890'
    htmlEl.dataset['wfSite'] = '61e48a462079c356c6452042'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })

      return active.isAsync ? next : loading
    }))

    setInterval(() => {
      this.setState({
        textIndex: this.state.textIndex + 1,
      });
    }, 3000);    
  }

  render() {
    // const proxies = IndexView.Controller !== IndexView ? transformProxies(this.props.children) : {
      
    // }

    const buttonConnectProps = {
      image:"contact-white.png",
      displayText:"Get in touch",
      link: "/connect",
    }
    const buttonManifestoProps = {
      image:"ideas-white3x.png",
      displayText:"Read our Manifesto",
      link: "/manifesto",
    }
    // const buttonPortfolioProps = {
    //   image:"ideas-white3x.png",
    //   displayText:"Investment Portfolio",
    //   link: "index.html",
    // }

    const buttonScrollToMissionSectionProps = {
      link:"index#mission",
      webflowanimate:false,
    }

    const buttonScrollToNutshellSectionProps = {
      link:"index#altalurra101",
    }

    const buttonScrollToOpportunitiesSectionProps = {
      link:"index#opportunities",
    }    
    const buttonScrollToTopSectionProps = {
      link:"index#top",
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/altalurra.webflow.css);
        ` }} />
        <span className="af-view">
          <div>
            <div className="af-class-page-wrapper">
              <SiteHeader displayHome={false} />
              <div className="af-class-section af-class-home-hero af-class-wf-section animate-xappear">
                <div className="af-class-container-2 w-container"><img src="images/dune-illustration-bg-black3x.png" loading="lazy" sizes="50vw" srcSet="images/dune-illustration-bg-black3x-p-500.png 500w, images/dune-illustration-bg-black3x-p-800.png 800w, images/dune-illustration-bg-black3x-p-1080.png 1080w, images/dune-illustration-bg-black3x.png 1567w" alt="Altalurra Illustration" className="af-class-image-2" /></div>
                <div className="af-class-container-default w-container">
                  <div>
                    <div className="af-class-mg-bottom-large-72px">
                      <div className="af-class-title-wrapper af-class-mg-bottom-24px">
                        <h1 className="af-class-display-1 af-class-color-white af-class-home-hero-title animate-xappearfadein">We partner with<br />impact-driven<br />tech founders</h1>
                        <div className="af-class-inline af-class-underline-bg af-class-mg-bottom-mb-32px animate-xappearxfadein">
                          <div className="af-class-fill-block af-class-bg-neutral-100 bg-altalurrablue-normal opacity-50" />
                        </div>
                      </div>
                      <div className="af-class-paragraph-large af-class-home-hero-paragraph h-[80px]"> 
                        <ReactTextTransition
                          text={rollingTexts[this.state.textIndex % rollingTexts.length]}
                          springConfig={{ stiffness: 50, damping: 20 }}
                          noOverflow
                        />
                      </div>
                       {/* <p data-w-id="f4e69ca9-4bf5-636d-0b9c-3702a8342414" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} className="af-class-paragraph-large af-class-home-hero-paragraph">
                        <TypeAnimation
                          cursor={false}
                          sequence={['Growing together to fight climate change and to reinvent our world', 1000, 'Transforming existing industries and infrastructure through sustainable technologies and supply chain management', 1000, 'Generating positive social impact through inclusion, technology and information access', 1000]}
                          wrapper="span"
                          repeat={Infinity}
                          className="type-animate"
                        />
                      </p> */}
                    </div>
                    <div className="af-class-_2-buttons-wrap max-w-[60vw] flex justify-between items-center flex-col lg:flex-row gap-y-3 animate-xappearfadein">
                      <ButtonFullEffects {...buttonConnectProps} />
                      <ButtonFullEffects {...buttonManifestoProps} />
                      <div>
                        <a href="https://impactassets.org/IA-50" target="_blank" rel="noreferrer" aria-current="page" className="af-class-header-link w-nav-brand w--current"><img src="images/ImpactAssets_Green.png" width={140} alt="Impact Assets Top 50" /></a>
                      </div>
                    </div>
                  </div>
                </div>
                <ScrollSectionButton {...buttonScrollToMissionSectionProps} />
              </div>
              <div id="mission" className="af-class-section af-class-wf-section min-h-[100vh] content-center items-center place-content-center">
                <div className="af-class-container-default w-container flex content-center items-center place-content-center">
                  <div className="af-class-_2-column-grid af-class-larger-right-column self-center place-self-center">
                    <div>
                      <div data-w-id="9154ae7b-ed5f-a495-270d-69a8eef70c82" className="af-class-dash---130px af-class-mg-bottom-32px">
                        <div className="af-class-fill-block af-class-bg-primary-1" />
                      </div>
                      <h2 data-w-id="3eb5ca53-81ff-7c0d-f71c-03856ef027cb" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} className="af-class-display-2 af-class-mg-bottom-0px af-class-titleonwhite">Our Mission</h2>
                    </div>
                    <div className="af-class-div-block-2 af-class-color-neutral-800 content-center">
                      <p data-w-id="d96c1c44-65fc-014e-6265-77ff9cce416d" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} className="af-class-paragraph-large af-class-mg-bottom-large-72px">
                        We back <span className="af-class-text-highlight">emerging and innovative founders</span> achieving <span className="af-class-text-highlight">positive impact through technology and sustainable business models</span>
                      </p>
                      <p data-w-id="f02d3115-ee9f-7167-07e7-1f78ea3c64f7" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} className="af-class-paragraph-large af-class-mg-bottom-large-72px">
                        We boost startups' growth by leveraging (1) our <span className="af-class-text-highlight">product strategy and operational experience</span>, (2) our <span className="af-class-text-highlight">financial engineering skills</span>, and (3) <span className="af-class-text-highlight">our global expert network</span>
                      </p>
                      <p data-w-id="f02d3115-ee9f-7167-07e7-1f78ea3c64f7" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} className="af-class-paragraph-large af-class-mg-bottom-large-72px">
                        We multiply the impact and increase the value of <span className="af-class-text-highlight">mission-driven startups</span> via a <span className="af-class-text-highlight">transparent impact assessment framework</span> and an <span className="af-class-text-highlight">ecosystem building approach</span>
                      </p>
                      <HashLink smooth data-w-id="f4e69ca9-4bf5-636d-0b9c-3702a8342421" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} to="/strategy" aria-current="page" className="af-class-button-primary w-inline-block w--current">
                        <div>Learn about our approach<span className="af-class-button-arrow"></span></div>
                        <div className="af-class-underline-wrap">
                          <div className="af-class-button-underline" />
                        </div>
                      </HashLink>
                    </div>
                  </div>
                </div>
                <ScrollSectionButton {...buttonScrollToOpportunitiesSectionProps} />
              </div>
              <div data-w-id="9154ae7b-ed5f-a495-270d-69a8eef70c82" className="relative min-w-[100vw] h-[2px]">
                    <div className="af-class-fill-block bg-altalurragreen-normal" />
                </div>
              <div id="opportunities" className="af-class-section af-class-wf-section min-h-[100vh] content-center items-center place-content-center">
                <div className="af-class-container-default w-container content-center items-center place-content-center">
                  {/* <div data-w-id="9154ae7b-ed5f-a495-270d-69a8eef70c82" className="af-class-dash---130px af-class-mg-bottom-32px">
                    <div className="af-class-fill-block af-class-bg-primary-1" />
                  </div>
                  <h2 data-w-id="3eb5ca53-81ff-7c0d-f71c-03856ef027cb" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} className="af-class-display-2 af-class-mg-bottom-0px af-class-titleonwhite mb-10">
                    Timing is Key
                  </h2> */}
                  <div className="af-class-_2-column-grid af-class-larger-left-column self-center place-self-center md:mt-[200px]">
                    <div className="af-class-div-block-2 af-class-color-neutral-800 af-class-box-right">
                      <p data-w-id="3eb5ca53-81ff-7c0d-f71c-03856ef027cb" className="af-class-paragraph-large mb-10">
                        We believe that impact-driven tech investing now offers unique opportunities for both investors and entrepreneurs to seize higher returns while pursuing purpose and growth 
                      </p>
                      {/* <p data-w-id="d96c1c44-65fc-014e-6265-77ff9cce416d" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} className="af-class-paragraph-medium">
                        <span className="af-class-text-highlight">Under-invested Impact Areas - </span> The vast majority of sustainability-driven investments flow into mobility and energy. Industrial transformation, infrastructure, resource management, agriculture have only received 25% ot the total investments while representing over 81% of the contributions to emissions. In parallel, other impact dimensions such as social impact are largely absent from the picture in developed countries. 
                      </p>
                      <p data-w-id="d96c1c44-65fc-014e-6265-77ff9cce416d" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} className="af-class-paragraph-medium md:ml-4">
                        <span className="af-class-text-highlight">Regulatory Tailwinds - </span> Political and regulatory pressures are progressively materializing, while opening opportunities for specialists to provide the necessary infrastructure for impact data and information access. This results in additional business advantages through combinations of investment, corporate and consumer incentives.
                      </p>
                      <p data-w-id="d96c1c44-65fc-014e-6265-77ff9cce416d" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} className="af-class-paragraph-medium md:ml-8">
                        <span className="af-class-text-highlight">Consumer Sentiment - </span> Initially sparked by climate change awareness, consumer sentiment is increasingly guiding choices, paving the way for startups to draw business advantages from their ethics and goals. This applies both to revenue generation and to talent attraction, and gets amplified by generational trends.
                      </p>
                      <p data-w-id="d96c1c44-65fc-014e-6265-77ff9cce416d" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} className="af-class-paragraph-medium md:ml-4">
                        <span className="af-class-text-highlight">Future Suppliers of Choice - </span> As existing corporates scramble to achieve ESG and Impact goals, their supply chains constitute the biggest challenges. New providers of technology, parts and components, and innovators who will be able to demonstrate superior impact management will be the partner of choices.
                      </p>
                      <p data-w-id="d96c1c44-65fc-014e-6265-77ff9cce416d" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} className="af-class-paragraph-medium">
                        <span className="af-class-text-highlight">Future Targets of Choice - </span> Corporations and investment firms that are subject to new impact-weighted targets will need to invest into challengers, making founders who will adopt a positive impact stance from the ground up highly valuable targets.
                      </p> */}
                      <div className="flex flex-col gap-y-6">
                      <div
                        // onMouseEnter={(e) => this.toggleOpen(0)}
                        // onMouseLeave={(e) => this.toggleOpen(0)}
                      >
                      <Collapsible 
                        containerElementProps={{ 'data-w-id':"3eb5ca53-81ff-7c0d-f71c-03856ef027cb"}} 
                        className="af-class-paragraph-large" 
                        // open={this.state.collapsiblesStates[0]}
                        onOpening={() => this.setState({ noneOpen: false})}
                        onClose={() => this.setState({ noneOpen: true })}
                        transitionTime={300}
                        contentContainerTagName="p"  
                        // trigger={<><i class="fa-solid fa-circle-arrow-right animate-xbounce"></i><div className='ml-4 inline'>Under-invested Impact Areas</div></>} 
                        trigger={<><i class={"fa-solid fa-circle-arrow-right" + (this.state.noneOpen ? " animate-xbounce":"")}></i><div className='ml-4 inline'>Under-Invested Impact Areas</div></>} 
                        triggerWhenOpen={<><i class="fa-solid fa-circle-arrow-down"></i><div className='ml-4 inline'>Under-Invested Impact Areas</div></>}
                        triggerClassName="af-class-paragraph-large af-class-text-highlight" 
                        triggerOpenedClassName="af-class-paragraph-large af-class-text-highlight"
                      >                        
                        <p className="af-class-paragraph-large mt-4">
                          The vast majority of sustainability-driven investments flow into mobility and energy. Industrial transformation, infrastructure, resource management and agriculture have only received 25% of the total investment while representing over 81% of the contribution to emissions. In parallel, other key dimensions such as social impact are largely absent from the picture in developed countries. 
                        </p>
                      </Collapsible>
                      </div>                      
                      <div
                        // onMouseEnter={(e) => this.toggleOpen(1)}
                        // onMouseLeave={(e) => this.toggleOpen(1)}
                      >                        
                      <Collapsible 
                        containerElementProps={{ 'data-w-id':"3eb5ca53-81ff-7c0d-f71c-03856ef027cb"}} 
                        className="af-class-paragraph-large" 
                        // open={this.state.collapsiblesStates[1]}
                        onOpening={() => this.setState({ noneOpen: false})}
                        onClose={() => this.setState({ noneOpen: true })}
                        transitionTime={300}
                        contentContainerTagName="p"  
                        trigger={<><i class={"fa-solid fa-circle-arrow-right" + (this.state.noneOpen ? " animate-xbounce":"")}></i><div className='ml-4 inline'>Regulatory Tailwinds</div></>} 
                        triggerWhenOpen={<><i class="fa-solid fa-circle-arrow-down"></i><div className='ml-4 inline'>Regulatory Tailwinds</div></>}
                        triggerClassName="af-class-paragraph-large af-class-text-highlight" 
                        triggerOpenedClassName="af-class-paragraph-large af-class-text-highlight"
                      >                        
                        <p className="af-class-paragraph-large mt-4">
                          Political and regulatory pressures are progressively materializing, creating opportunities for specialists to provide the necessary infrastructure for driving impact transparency and adoption. This results in additional business advantages through the combination of investment, corporate and consumer incentives. 
                        </p>
                      </Collapsible>
                      </div>
                      <div
                        // onMouseEnter={(e) => this.toggleOpen(2)}
                        // onMouseLeave={(e) => this.toggleOpen(2)}
                      >
                      <Collapsible 
                        containerElementProps={{ 'data-w-id':"3eb5ca53-81ff-7c0d-f71c-03856ef027cb"}} 
                        className="af-class-paragraph-large" 
                        // open={this.state.collapsiblesStates[2]}
                        onOpening={() => this.setState({ noneOpen: false})}
                        onClose={() => this.setState({ noneOpen: true })}
                        transitionTime={300}
                        contentContainerTagName="p"  
                        trigger={<><i class={"fa-solid fa-circle-arrow-right" + (this.state.noneOpen ? " animate-xbounce":"")}></i><div className='ml-4 inline'>Consumer Sentiment</div></>} 
                        triggerWhenOpen={<><i class="fa-solid fa-circle-arrow-down"></i><div className='ml-4 inline'>Consumer Sentiment</div></>}
                        triggerClassName="af-class-paragraph-large af-class-text-highlight" 
                        triggerOpenedClassName="af-class-paragraph-large af-class-text-highlight"
                      >                        
                        <p className="af-class-paragraph-large mt-4">
                          Initially sparked by climate change awareness, "conscious" consumer sentiment is increasingly guiding choices, paving the way for startups to draw business advantages from their ethics and goals. This applies both to revenue generation and to talent attraction and is amplified by generational trends.
                        </p>
                      </Collapsible>
                      </div>
                      <div
                        // onMouseEnter={(e) => this.toggleOpen(3)}
                        // onMouseLeave={(e) => this.toggleOpen(3)}
                      >
                      <Collapsible 
                        containerElementProps={{ 'data-w-id':"3eb5ca53-81ff-7c0d-f71c-03856ef027cb"}} 
                        className="af-class-paragraph-large" 
                        // open={this.state.collapsiblesStates[3]}
                        onOpening={() => this.setState({ noneOpen: false})}
                        onClose={() => this.setState({ noneOpen: true })}
                        transitionTime={300}
                        contentContainerTagName="p"  
                        trigger={<><i class={"fa-solid fa-circle-arrow-right" + (this.state.noneOpen ? " animate-xbounce":"")}></i><div className='ml-4 inline'>Suppliers of Choice</div></>} 
                        triggerWhenOpen={<><i class="fa-solid fa-circle-arrow-down"></i><div className='ml-4 inline'>Suppliers of Choice</div></>}
                        triggerClassName="af-class-paragraph-large af-class-text-highlight" 
                        triggerOpenedClassName="af-class-paragraph-large af-class-text-highlight"
                      >                        
                        <p className="af-class-paragraph-large mt-4">
                          As existing corporations scramble to collect data on their emissions, and to set and achieve their ESG and impact targets, their supply chains will continue to be their biggest challenges. Innovative new providers capable of demonstrating superior impact scores and disciplines will become the partners of choice. 
                        </p>
                      </Collapsible>
                      </div>
                      <div
                        // onMouseEnter={(e) => this.toggleOpen(4)}
                        // onMouseLeave={(e) => this.toggleOpen(4)}
                      >
                      <Collapsible 
                        containerElementProps={{ 'data-w-id':"3eb5ca53-81ff-7c0d-f71c-03856ef027cb"}} 
                        className="af-class-paragraph-large" 
                        // open={this.state.collapsiblesStates[4]}
                        onOpening={() => this.setState({ noneOpen: false})}
                        onClose={() => this.setState({ noneOpen: true })}
                        transitionTime={300}
                        contentContainerTagName="p"  
                        trigger={<><i class={"fa-solid fa-circle-arrow-right" + (this.state.noneOpen ? " animate-xbounce":"")}></i><div className='ml-4 inline'>Targets of Choice</div></>} 
                        triggerWhenOpen={<><i class="fa-solid fa-circle-arrow-down"></i><div className='ml-4 inline'>Targets of Choice</div></>}
                        triggerClassName="af-class-paragraph-large af-class-text-highlight" 
                        triggerOpenedClassName="af-class-paragraph-large af-class-text-highlight"
                      >                        
                        <p className="af-class-paragraph-large mt-4">
                          Corporations and investment firms that are subject to new impact-weighted targets will need to invest in challengers, making founders who adopt a positive impact stance from the ground up highly valuable targets. 
                        </p>
                      </Collapsible>
                      </div>
                      </div>
                    </div>
                    <div id="w-node-_706af8c8-eaf5-a80a-b225-8c32b67cdc09-c6937890" className="af-class-inner-container-80 af-class-_100-in-mobile">
                      <div className="af-class-card af-class-sticky-card">
                        <div data-w-id="9154ae7b-ed5f-a495-270d-69a8eef70c82" className="af-class-dash---130px af-class-mg-bottom-32px">
                          <div className="af-class-fill-block af-class-bg-primary-1" />
                        </div>
                        <h2 data-w-id="3eb5ca53-81ff-7c0d-f71c-03856ef027cb" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} className="af-class-display-2 af-class-mg-bottom-0px af-class-titleonwhite mb-10">
                          Timing is Key
                        </h2>
                        <HashLink smooth data-w-id="f4e69ca9-4bf5-636d-0b9c-3702a8342421" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} to="/strategy#strategies" aria-current="page" className="af-class-button-primary w-inline-block w--current mt-6 md:mt-[200px]">
                          <div>Domains of action<span className="af-class-button-arrow"></span></div>
                          <div className="af-class-underline-wrap">
                            <div className="af-class-button-underline" />
                          </div>
                        </HashLink>
                      </div>
                    </div>
                  </div>
                </div>
                <ScrollSectionButton {...buttonScrollToNutshellSectionProps} />
              </div>
              <div id="altalurra101" className="af-class-section af-class-bg---90vh af-class-contact af-class-wf-section bg-[url('../images/Background_Mountain_Masked.jpg')] bg-cover min-h-[100vh] content-center items-center place-content-center">
                <div className="af-class-container-3 w-container content-center items-center place-content-center">
                  <h2 data-w-id="3eb5ca53-81ff-7c0d-f71c-03856ef027cb" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} className="af-class-display-2 af-class-mg-bottom-0px af-class-titleondark pt-8 text-center">Altalurra 101</h2>                
                  <NutshellContent />
                </div>
                <ScrollTopButton {...buttonScrollToTopSectionProps} />
              </div>
              <SiteFooter />
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default IndexView