import React from "react";
import {Helmet} from "react-helmet";
import NewsView from "./webflow/NewsView";

function News(props) {
    return (
        <>
            <Helmet>
                <title>ALTALURRA's News</title>
                <meta content="Learn about impact, our companies, and get updates from the team." name="description"/>
                <meta content="ALTALURRA's News" property="og:title"/>
                <meta content="Learn about impact, our companies, and get updates from the team." property="og:description"/>
                <meta content="https://altalurra-public.s3.amazonaws.com/altalurra-opengraph.png" property="og:image"/>
                <meta content="ALTALURRA's News" property="twitter:title"/>
                <meta content="Learn about impact, our companies, and get updates from the team." property="twitter:description"/>
                <meta content="https://altalurra-public.s3.amazonaws.com/altalurra-opengraph.png" property="twitter:image"/>
                <meta property="og:type" content="website"/>
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>
            <NewsView>
                {/* <myform {...props} /> */}
            </NewsView>
        </>
    );
}

export default News;