/* eslint-disable */

import React from 'react'
import { HashLink } from 'react-router-hash-link';
import { createScope, map, transformProxies } from './helpers'
import ButtonFullEffects from '../../components/webflow/ButtonFullEffects'
import ScrollSectionButton from '../../components/webflow/ScrollSectionButton'
import SiteHeader from '../../components/SiteHeader'
import SiteFooter from '../../components/SiteFooter'

const scripts = [
  { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=61e48a462079c356c6452042").then(body => body.text()), isAsync: false },
  { loading: fetch("js/webflow.js").then(body => body.text()), isAsync: false },
]

let Controller

class ManifestoView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../Manifesto')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = ManifestoView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '6242288191da2b50c6937890'
    htmlEl.dataset['wfSite'] = '61e48a462079c356c6452042'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })

      return active.isAsync ? next : loading
    }))
  }

  render() {
    // const proxies = ManifestoView.Controller !== ManifestoView ? transformProxies(this.props.children) : {

    // }
    const buttonTogetherProps = {
      image:"pinch-easy-white.png",
      displayText:"Investment Themes",
      link: "/strategy",
    }
    const buttonPurposeGrowthProps = {
      image:"sustainable-growth-white.png",
      displayText:"Purpose & Growth",
      link: "/impact",
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/altalurra.webflow.css);
        ` }} />
        <span className="af-view">
          <div>
            <div className="af-class-page-wrapper">
              <SiteHeader displayHome={true} />
              <div className="af-class-section af-class-home-hero af-class-wf-section  bg-[url('../images/Background_Hills_Masked.jpg')] bg-cover animate-xappear">
               <div className="af-class-container-default w-container">
                  <div className="af-class-mg-bottom-large-72px">
                    <div className="af-class-title-wrapper af-class-mg-bottom-24px">
                      <h2 className="af-class-display-2 inline text-altalurrablue-normal font-bold text-center animate-xappearfadein">Manifesto</h2>                
                      <div className="af-class-inline af-class-underline-bg af-class-mg-bottom-mb-32px animate-xappearxfadein">
                        <div className="af-class-fill-block af-class-bg-neutral-100 bg-altalurrablue-normal opacity-50" />
                      </div> 
                    </div> 
                    <div className="af-class-paragraph-large af-class-manifesto-hero-paragraph text-gray-300 hover:text-gray-500 animate-xappearfadein duration-150">
                      <div className="hover:text-white duration-500">
                        <span className="">
                          We founded Altalurra as impact-driven optimists who see technology as a force for good and believe that the startup ecosystem will have a positive global impact.&nbsp;
                        </span>
                        <span className="">
                          Our objective is to partner early with inspiring, ambitious tech founders: renegades who are reimagining the world and shaping it their way. 
                          {/* Breakthrough underserved businesses are starting and scaling with increasing frequency, powered by unparalleled engineering talent. */}
                        </span> 
                      </div>
                      <div className="hover:text-white duration-500">
                        <span className="">
                          <br />
                          Altalurra wants to boost your mission, because it is ours too. We recognize that venture capital would not exist without founders like you, who put everything on the line. We are thrilled when you choose to work with us and honored to support the realization of your dream.&nbsp;
                        </span> 
                        {/* <span className="">
                          We come to assist you, as much as you desire to. Through our backgrounds in technology, in ESG & impact, and in finance, it is our own intent to help you succeed and to bring focus on sustainable businesses as the norm.
                        </span>  */}
                      </div>
                      <div className="hover:text-white duration-500">
                        <p className="mb-4 mt-8">
                          Partnering with us, you can expect
                        </p>
                        <div className="flex flex-cols flex-wrap gap-y-4">
                          <div className="flex w-full flex-col lg:flex-row gap-x-1 gap-y-1 group">                              
                            <div className="flex-none w-[300px]"><ul className="list-disc list-inside p-0 m-0 space-0"><li><span className="font-bold group-hover:text-altalurrablue-normal text-left duration-150">Alignment of interests</span></li></ul></div>
                            <div className="flex w-full">We commit to support your impact-driven business with unbiased counsel, and honesty to help your goals.</div>
                          </div>
                          <div className="flex w-full flex-col lg:flex-row gap-x-1 gap-y-1 group">                              
                            <div className="flex-none w-[300px]"><ul className="list-disc list-inside p-0 m-0 space-0"><li><span className="font-bold group-hover:text-altalurrablue-normal text-left duration-150">Boutique service</span></li></ul></div>
                            <div>We are a small, highly collaborative team who work across the portfolio no matter how big or small the task. We do the research, deep diving into your startup, products and ecosystem. Some might come in with a prescriptive approach, whereas we have a uniquely bespoke process tailored to every situation. Trust is essential and every relationship matters.</div>
                          </div>
                          <div className="flex w-full flex-col lg:flex-row gap-x-1 gap-y-1 group">                              
                            <div className="flex-none w-[300px]"><ul className="list-disc list-inside p-0 m-0 space-0"><li><span className="font-bold group-hover:text-altalurrablue-normal text-left duration-150">Growth mindset</span></li></ul></div>
                            <div>The founding members of Altalurra have successfully grown multiple companies from the ground up. We know what works and what doesn't, and will help generate revenue, and quickly, without impeding your mission or imposing a financing trajectory.</div>
                          </div>
                          <div className="flex w-full flex-col lg:flex-row gap-x-1 gap-y-1 group">                              
                            <div className="flex-none w-[300px]"><ul className="list-disc list-inside p-0 m-0 space-0"><li><span className="font-bold group-hover:text-altalurrablue-normal text-left duration-150">Low friction</span></li></ul></div>
                            <div>We are humble, curious, open and straightforward. We appreciate your time and commit to a simple, transparent business process and quick turnarounds.</div>
                          </div>
                          <div className="flex w-full flex-col lg:flex-row gap-x-1 gap-y-1 group">                              
                            <div className="flex-none w-[300px]"><ul className="list-disc list-inside p-0 m-0 space-0"><li><span className="font-bold group-hover:text-altalurrablue-normal text-left duration-150">Long term engagement</span></li></ul></div>
                            <div>Success is not built overnight, and venture capital is a business that rewards patience. We understand that and are long term partners for both you and your business.</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="af-class-_2-buttons-wrap flex-col md:flex-row gap-x-8 gap-y-3 animate-xappearfadein">
                    <ButtonFullEffects {...buttonTogetherProps} />
                    <ButtonFullEffects {...buttonPurposeGrowthProps} />
                  </div>
                </div>
              </div>
              {/* <div className="af-class-section af-class-bg-neutral-100 af-class-hidden af-class-wf-section">
                <div className="af-class-container-default w-container">
                  <div>
                    <div className="af-class-_2-column-grid">
                      <div id="w-node-_706af8c8-eaf5-a80a-b225-8c32b67cdc09-c6937890" className="af-class-inner-container-80 af-class-_100-in-mobile">
                        <div className="af-class-card af-class-sticky-card">
                          <div className="af-class-dash---130px af-class-mg-bottom-32px">
                            <div className="af-class-fill-block af-class-bg-primary-1" />
                          </div>
                          <h2 data-w-id="706af8c8-eaf5-a80a-b225-8c32b67cdc0d" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} className="af-class-display-2">Industries we invest in</h2>
                          <p data-w-id="706af8c8-eaf5-a80a-b225-8c32b67cdc0f" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}}>Lorem ipsum dolor sit amet consectetur adipiscing elit dolor ipsum scelerisque quis tellus suscipit sit consectetur imperdiet facilisis.</p>
                        </div>
                      </div>
                      <div>
                        <div className="af-class-card af-class-invested-industries af-class-first"><img src="images/invest-icon-01-investor-x-template.svg" alt="icon" className="af-class-mg-bottom-32px" />
                          <h3>Mobile &amp; Tablet Apps</h3>
                          <p>Lorem ipsum dolor sit amet consectetur adipiscing elit dolor ipsum scelerisque quis tellus suscipit sit consectetur</p>
                        </div>
                        <div className="af-class-card af-class-invested-industries"><img src="images/invest-icon-02-investor-x-template.svg" alt="icon" className="af-class-mg-bottom-32px" />
                          <h3>Cloud &amp; Desktop Apps</h3>
                          <p>Lorem ipsum dolor sit amet consectetur adipiscing elit dolor ipsum scelerisque quis tellus suscipit sit consectetur</p>
                        </div>
                        <div className="af-class-card af-class-invested-industries af-class-last"><img src="images/invest-icon-03-investor-x-template.svg" alt="icon" className="af-class-mg-bottom-32px" />
                          <h3>Software as a Service</h3>
                          <p>Lorem ipsum dolor sit amet consectetur adipiscing elit dolor ipsum scelerisque quis tellus suscipit sit consectetur</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <SiteFooter />
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default ManifestoView

/* eslint-enable */