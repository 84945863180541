import React from "react"

// @notus-pro/react
import classnames from "classnames";

export default function MatchContent({
  button1Text,
  button2Text,
  nbCols,
  image,
  cards1,
  cards2
}) {
  const [selection, setSelection] = React.useState(2);
  const [oldInTransition, setOldInTransition] = React.useState(false);
  const [newInTransition, setNewInTransition] = React.useState(false);
  const toggleSelection = (e, newSelection) => {
    e.preventDefault();
    if (!newInTransition && !oldInTransition) {
      setOldInTransition(true);
      setTimeout(function () {
        setSelection(newSelection);
      }, 500);
      setTimeout(function () {
        setOldInTransition(false);
        setNewInTransition(true);
      }, 600);
      setTimeout(function () {
        setNewInTransition(false);
      }, 1100);
    }
  };

  return (
    <div className="animate-xappearfadein">
      <div className="place-content-center flex flex-wrap gap-4 lg:gap-6 flex-col lg:flex-row justify-center self-stretch">
        <div className="flex flex-initial w-1/2 lg:w-1/3">
          <button
            className={classnames(
              "flex flex-1 outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out font-bold",
              "text-altalurrablue-normal bg-transparent whitespace-pre",
              "text-3xl leading-4 px-6 py-1 lg:py-2 w-3/4 text-center items-center justify-center",
              { 
                "opacity-100": selection === 1,
                "opacity-50": selection !== 1,
              },
              "active:opacity-100 hover:opacity-100"
            )}
            onClick={(e) => toggleSelection(e, 1)}          
          >
            <i class={classnames(
              "align-bottom text-xl text-altalurrablue-normal fa-solid fa-circle-arrow-right",
              {
                "contents": selection === 1,
                "hidden": selection !== 1,
              }
            )}></i>
            {"  " + button1Text}
          </button>
          {/* <div className="af-class-underline-wrap">
            <div className="af-class-button-underline bg-altalurrablue-normal group-hover:bg-transparent" />
          </div> */}
        </div>
        {/* <div classname="flex flex-none w-[30px]">
        </div> */}
        <div className="flex flex-initial w-1/2 lg:w-1/3">
          <button
            className={classnames(
              "flex flex-1 outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out font-bold",
              "text-altalurragreen-normal bg-transparent whitespace-pre",
              "text-3xl leading-4 px-6 py-1 lg:py-2 w-3/4 text-center items-center justify-center",
              { 
                "opacity-100": selection === 2,
                "opacity-50": selection !== 2,
              },
              "active:opacity-100 hover:opacity-100"
            )}
            onClick={(e) => toggleSelection(e, 2)}          
          >
            <i class={classnames(
              "align-bottom text-xl text-altalurragreen-normal fa-solid fa-circle-arrow-right",
              {
                "contents": selection === 2,
                "hidden": selection !== 2,
              }
            )}></i>
            {"  " + button2Text}
          </button>
        </div>
      </div>


      <div
        className="relative align-middle bg-transparent text-center"
        style={{ backgroundImage: "url(" + image + ")" }}
      >
        <div className="container mx-auto px-4 py-8">
          <div className={classnames(
            "mt-4 flex flex-wrap gap-y-2 justify-center",
            {
              hidden: selection !== 1,
              block: selection === 1,
              "opacity-0 scale-95": selection === 1 && oldInTransition,
              "opacity-100 scale-100": selection === 1 && newInTransition,
            }
            )}>
            {cards1.map((prop, key) => (
              // <div key={key} className="flex py-4 px-6 relative w-full h-full lg:w-1/2">
              <div key={key} className={"flex item-stretch py-2 px-3.5 w-1/1 lg:w-1/" + nbCols}>
                <div className="group bg-altalurradark-masked/25 rounded-lg h-full w-full border border-altalurrablue-normal flex flex-col align-middle transition duration-500 hover:scale-105 sm:hover:scale-110">
                <h4 className="flex-none font-bold text-altalurrablue-normal mx-auto mb-4 mt-4 text-base lg:text-lg xl:text-2xl uppercase">
                    {prop.title}
                  </h4>
                  <p className="flex-initial mx-8 overflow-y-auto text-sm xl:text-base text-center mb-2">
                    {prop.description}
                  </p>
                  <ul className="list-disc list-outside text-sm xl:text-base text-left overflow-y-auto">
                  {prop.list.map((item, key2) => 
                    <li key={key2}>{item}</li>
                  )}
                  </ul>
                </div>
             </div>
            ))}
          </div>

          <div className={classnames(
            "mt-4 flex flex-wrap gap-y-2 justify-center",
            {
              hidden: selection !== 2,
              block: selection === 2,
              "opacity-0 scale-95": selection === 2 && oldInTransition,
              "opacity-100 scale-100": selection === 2 && newInTransition,
            }
            )}>
            {cards2.map((prop, key) => (
              // <div key={key} className="flex py-4 px-6 relative w-full h-full lg:w-1/2">
              <div key={key} className={"flex item-stretch py-2 px-3.5 w-1/1 lg:w-1/" + nbCols}>
                <div className="group bg-altalurradark-masked/25 rounded-lg h-full w-full border border-altalurragreen-normal flex flex-col align-middle transition duration-500 hover:scale-105 sm:hover:scale-110">
                  <h4 className="flex-none font-bold text-altalurragreen-normal mx-auto mb-4 mt-4 text-base lg:text-lg xl:text-2xl uppercase">
                    {prop.title}
                  </h4>
                  <p className="flex-initial mx-8 overflow-y-auto text-sm xl:text-base text-center mb-2">
                    {prop.description}
                  </p>
                  <ul className="list-disc list-outside text-sm xl:text-base text-left overflow-y-auto">
                  {prop.list.map((item, key2) => 
                    <li key={key2}>{item}</li>
                  )}
                  </ul>
                </div>
             </div>
            ))}
          </div>
        </div>

      </div>

      {/* <div className="place-self-center h-[60vh] grid grid-cols-8 grid-rows-1 mt-10 w-full">
        <div className="col-span-1 row-span-1 flex flex-col items-center justify-center align-middle">
          <div className="h-full self-center flex flex-col items-center justify-items-center justify-center align-middle">
            <h4 className="flex-none font-bold text-altalurragreen-600 mx-auto mb-8 mt-4 text-base lg:text-lg xl:text-2xl">
              Title
            </h4>
            <p className="flex-initial mx-auto overflow-y-auto text-sm lg:text-base xl:text-lg">
              Text
            </p>
          </div>
        </div>
      </div> */}
    </div>
  )
}