/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'
import ButtonFullEffects from '../../components/webflow/ButtonFullEffects'
import SiteHeader from '../../components/SiteHeader'
import SiteFooter from '../../components/SiteFooter'
import TypeFormConnect from '../../components/TypeFormConnect'

const scripts = [
  { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=61e48a462079c356c6452042").then(body => body.text()), isAsync: false },
  { loading: fetch("js/webflow.js").then(body => body.text()), isAsync: false },
]

let Controller

class ConnectView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../Connect')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = ConnectView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '6242288191da2be29493789b'
    htmlEl.dataset['wfSite'] = '61e48a462079c356c6452042'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })

      return active.isAsync ? next : loading
    }))
  }

  render() {
    // const proxies = ConnectView.Controller !== ConnectView ? transformProxies(this.props.children) : {

    // }

    const buttonNewsletterProps = {
      image:"contact-white.png",
      displayText:"Subscribe to our newsletter",
      link: "/news#subscribe",
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/altalurra.webflow.css);
        ` }} />
        <span className="af-view">
          <div>
            <div className="af-class-page-wrapper">
              <SiteHeader />
              <div className="af-class-section af-class-bg---90vh af-class-contact af-class-wf-section bg-[url('../images/Background_Trees_Masked.jpg')] bg-cover animate-xappear">
                <div className="af-class-container-default w-container">
                  <div className="af-class-title-wrapper af-class-mg-bottom-24px lg:mb-12">
                    <h2 className="af-class-display-2 inline text-altalurrablue-normal font-bold text-center animate-xappearfadein">Let's work together</h2>                
                    <div className="af-class-inline af-class-underline-bg af-class-mg-bottom-mb-32px animate-xappearxfadein">
                      <div className="af-class-fill-block af-class-bg-neutral-100 bg-altalurrablue-normal opacity-50" />
                    </div> 
                  </div> 
                  {/* <h1 className="af-class-display-1 af-class-color-white animate-xappearfadein">Let's work together</h1> */}
                  <p className="af-class-paragraph-large text-center animate-xappearfadein">If we sparked your interest, please feel free to contact us at <a href="mailto:together@altalurra.com" className="af-class-not-a-link af-class-color-white inline text-altalurrablue-normal hover:text-altalurragreen-normal">together@altalurra.com</a>. <br />For a faster answer, please take the time to fill out our form. Talk soon!</p>
                  <div className="af-class-_2-buttons-wrap place-content-center flex-col md:flex-row gap-x-8 gap-y-3 mb-10 animate-xappearfadein">
                    <ButtonFullEffects {...buttonNewsletterProps} />
                  </div>
                  <div className="af-class-div-block-6 scrollbar scrollbar-thumb-transparent scrollbar-track-transparent">
                    <TypeFormConnect />
                  </div>
                  {/* <div className="af-class-div-block-5">
                    <div className="af-class-contact-links-wrapper">
                      <a href="mailto:together@altalurra.com" className="af-class-not-a-link af-class-color-white">together@altalurra.com</a>
                      <div className="af-class-text-separator af-class-vertical af-class-large" />
                    </div>
                  </div> */}
                </div>
              </div>
              <SiteFooter />
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default ConnectView

/* eslint-enable */