import React from "react";
import {Helmet} from "react-helmet";
import StrategyView from "./webflow/StrategyView";

function Strategy(props) {
    return (
        <>
            <Helmet>
                <title>A Transparent Investment Approach into Climate Tech, Industry and Infrastructure Tech, and Social Impact Tech</title>
                <meta content="We help Entrepreneurs build Impact-Driven Technology Companies." name="description"/>
                <meta content="A Transparent Investment Approach into Climate Tech, Industry and Infrastructure Tech, and Social Impact Tech" property="og:title"/>
                <meta content="We help Entrepreneurs build Impact-Driven Technology Companies." property="og:description"/>
                <meta content="https://altalurra-public.s3.amazonaws.com/altalurra-opengraph.png" property="og:image"/>
                <meta content="A Transparent Investment Approach into Climate Tech, Industry and Infrastructure Tech, and Social Impact Tech" property="twitter:title"/>
                <meta content="We help Entrepreneurs build Impact-Driven Technology Companies." property="twitter:description"/>
                <meta content="https://altalurra-public.s3.amazonaws.com/altalurra-opengraph.png" property="twitter:image"/>
                <meta property="og:type" content="website"/>
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>
            <StrategyView>
                {/* <myform {...props} /> */}
            </StrategyView>
        </>
    );
}

export default Strategy;