/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import PropTypes from "prop-types";
import { HashLink } from 'react-router-hash-link';
// components

// //Examples props declaration
// const buttonConnectProps = {
//   image:"contact-white.png",
//   displayText:"Get in touch",
//   link: "connect.html",
// }
// const buttonPortfolioProps = {
//   image:"ideas-white3x.png",
//   displayText:"Investment Portfolio",
//   link: "index.html",
// }
// //Examples usage
// <ButtonFullEffects {...buttonConnectProps} />
// <ButtonFullEffects {...buttonPortfolioProps} />

export default function ButtonFullEffects({
  image,
  displayText,
  link,
}) {
  return (
    <span >
    <style dangerouslySetInnerHTML={{ __html: `
      @import url(/css/normalize.css);
      @import url(/css/webflow.css);
      @import url(/css/altalurra.webflow.css);
    ` }} />
    <span className="af-view af-class-_2-buttons-wrap af-class-button-primary af-class-buttononblack bg-transparent">
      <HashLink smooth to={link} aria-current="page" className="af-class-button-primary af-class-buttononblack w-inline-block bg-transparent">
        <div className="af-class-div-block">
          <div>{displayText}</div>
          <img src={"images/"+ image} loading="lazy" width={30} height={30} alt="follow me" className="af-class-button-dune" />
        </div>
        <div className="af-class-underline-wrap">
          <div className="af-class-button-underline af-class-white" />
        </div>
      </HashLink>
    </span>
  </span>
  );
}
ButtonFullEffects.defaultProps = {
};

ButtonFullEffects.propTypes = {
  image: PropTypes.string.isRequired,
  displayText: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};
