import React from "react"

// @notus-pro/react
import MultipleCardsWrapper from "./notus/MultipleCardsWrapper"

const props = {
  nbCols: 2,
  image: "",
  cards: [
    {
      icon: "fa-solid fa-circle-nodes",
      color: "altalurragreen",
      title: "Investment Themes",
      description: 
        "Altalurra Ventures focuses on startups leveraging technology to achieve their mission. Our investment strategy spans three interconnected and under-invested themes - Climate Tech, Industry & Infrastructure Tech, and Social Impact Tech.  Altalurra will act as a catalyst across these themes to  develop valuable partnerships and trigger virtuous value cycles.",
      link: { href: "/strategy#strategies", children: "Get more details" },
    },
    {
      icon: "fa-solid fa-handshake-angle",
      color: "altalurragreen",
      title: "Value-Added Partners",
      description:
        "Venture capital is a way to scale our passion for disruptive technologies and to increase the impact we have as individuals. We join forces with founders and help them scale their impact by helping them design marketable and scalable solutions, backed by a solid operational strategy. We help investors by providing in-depth market knowledge and forward-thinking quantifiable impact metrics they can trust.",
      link: { href: "/strategy", children: "Find out how" },
    },
    {
      icon: "fa-solid fa-leaf",
      color: "altalurragreen",
      title: "Altalurra Impact Framework",
      description:
        "Central to our mission, is to foster the adoption of transparent and in-depth impact analyses across public and private capital markets. Leveraging our partners and our deep quantitative background in the ESG space, we rely on a 360\u00B0 review of impact drivers and operate with startups using an innovation framework, ultimately defining impact in monetary terms through Impact Value Added (IVA).",
      link: { href: "/impact", children: "Discover the approach" },
    },
    {
      icon: "fa-solid fa-bullseye",
      color: "altalurragreen",
      title: "Mission-Driven Entrepreneurs",
      description:
        "As former entrepreneurs, we understand that there isn't one linear path to success.  Founder support could be needed at any time, on any topic. We have strong expertise in cutting-edge technologies, product design and distribution, business development and go-to-market strategies. It enables us to assist you in defining the steps required to accomplish your own vision.",
      link: { href: "/team", children: "Meet the team" },
    },
  ],
  color: "black",
}

export default function NutshellContent() {
  return (
    <MultipleCardsWrapper {...props} />
  )
}