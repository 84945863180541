/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import PropTypes from "prop-types";
import { HashLink } from 'react-router-hash-link';

export default function ScrollTopButton({
  link,
}) {
  return (
    <span>
    <style dangerouslySetInnerHTML={{ __html: `
      @import url(/css/normalize.css);
      @import url(/css/webflow.css);
      @import url(/css/altalurra.webflow.css);
    ` }} />
      <span className="af-view">
        <HashLink smooth to={"/"+link} 
          className="af-class-scroll-down-button af-bottom-right af-top animate-custompulse"
        > {/* af-class-home-hero w-inline-block */}
          <img src="images/dune-stylized-bg-transparent@0.5x.png" 
            alt="Altalurra Logo" 
            className="af-class-image af-class-scroll-down-button" />
        </HashLink>
      </span>  
  </span>
  );
}
ScrollTopButton.defaultProps = {
  link: "#",
};

ScrollTopButton.propTypes = {
  link: PropTypes.string.isRequired,
};
