import React from "react";
import PropTypes from "prop-types";

// components
import CardInfoIconLeft from "./CardInfoIconLeft.js";

export default function MultipleCardsWrapper({ nbCols, image, cards }) {
  return (
    <>
      <div
        className="relative align-middle bg-transparent text-center"
        style={{ backgroundImage: "url(" + image + ")" }}
      >
        <div className="container mx-auto px-4 py-8">
          <div className="mt-12 flex flex-wrap">
            {cards.map((prop, key) => (
              // <div key={key} className="flex py-4 px-6 relative w-full h-full lg:w-1/2">
              <div key={key} className={"flex py-2 px-3.5 lg:w-1/" + nbCols}>
                <CardInfoIconLeft {...prop} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

MultipleCardsWrapper.defaultProps = {
  cards: [],
  color: "altalurragreen",
  nbCols: 2
};
MultipleCardsWrapper.propTypes = {
  image: PropTypes.string,
  // array of props to pass to the CardInfoIconLeft component
  cards: PropTypes.arrayOf(PropTypes.object),
  nbCols: PropTypes.number
};
