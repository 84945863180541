/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'
import SiteHeader from '../../components/SiteHeader'
import SiteFooter from '../../components/SiteFooter'
import TogetherContent from '../../components/TogetherContent'
import ScrollSectionButton from '../../components/webflow/ScrollSectionButton'
import ScrollTopButton from '../../components/ScrollTopButton'
import MultipleProfilesWrapper from '../../components/notus/MultipleProfilesWrapper'

const scripts = [
  { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=61e48a462079c356c6452042").then(body => body.text()), isAsync: false },
  { loading: fetch("js/webflow.js").then(body => body.text()), isAsync: false },
]

const profilesProps = {
  nbCols: 2,
  image: "",
  cards: [
    {
      image: "images/LA_2022_web.jpg",
      title: "Lionel Auguste",
      description: "General Partner & Co-Founder",
      affiliation: "",
      // list: [
      //   {
      //     icon: "fas fa-arrow-up-right-dots",
      //     color: "lightBlue",
      //     text: "Accute strategist",
      //   },
      //   {
      //     icon: "fas fa-bolt-lightning",
      //     color: "emerald",
      //     text: "Fast learner",
      //   },
      //   { icon: "fas fa-thumbs-up", color: "red", text: "Relentless optimist" },
      // ],
      link: "https://www.linkedin.com/in/lionelauguste",
      // // dropdown: {
      // //   icon: "fas fa-cog",
      // //   button: { color: "white" },
      // //   items: [
      // //     { href: "#pablo", children: "Edit Profile" },
      // //     { href: "#pablo", children: "Settings" },
      // //     { href: "#pablo", children: "Log out" },
      // //   ],
      // },
    },
    {
      image: "images/Axi_1208_1221low.jpg",
      title: "Fabien Couderc",
      description: "General Partner & Co-Founder",
      affiliation: "",
      // list: [
      //   {
      //     icon: "fas fa-chart-bar",
      //     color: "lightBlue",
      //     text: "Successful entrepreneur",
      //   },
      //   { icon: "fas fa-atom", color: "emerald", text: "Problem solver" },
      //   { icon: "fas fa-running", color: "red", text: "Full throttle ethos" },
      // ],
      link: "https://www.linkedin.com/in/fabiencouderc",
      // dropdown: {
      //   icon: "fas fa-cog",
      //   button: { color: "white" },
      //   items: [
      //     { href: "#pablo", children: "Edit Profile" },
      //     { href: "#pablo", children: "Settings" },
      //     { href: "#pablo", children: "Log out" },
      //   ],
      // },    
    },
  ],
  color: "black",
}

const profilesAdvisorsProps = {
  nbCols: 3,
  image: "",
  cards: [
    {
      image: "images/CS_debout_tete_web.jpg",
      title: "Carol Sirou",
      description: "Strategic Advisor, Advisory Board",
      affiliation: "CEO at Ethifinance",
      // list: [
      //   {
      //     icon: "fas fa-arrow-up-right-dots",
      //     color: "lightBlue",
      //     text: "Accute strategist",
      //   },
      //   {
      //     icon: "fas fa-bolt-lightning",
      //     color: "emerald",
      //     text: "Fast learner",
      //   },
      //   { icon: "fas fa-thumbs-up", color: "red", text: "Relentless optimist" },
      // ],
      link: "https://www.linkedin.com/in/carol-sirou",
      // // dropdown: {
      // //   icon: "fas fa-cog",
      // //   button: { color: "white" },
      // //   items: [
      // //     { href: "#pablo", children: "Edit Profile" },
      // //     { href: "#pablo", children: "Settings" },
      // //     { href: "#pablo", children: "Log out" },
      // //   ],
      // },
    },
    {
      image: "images/chris_profile_image_web.jpg",
      title: "Chris Cormack",
      description: "Venture Advisor, Decarbonization",
      affiliation: "Co-Founder & MD at The Quant Foundry",
      // list: [
      //   {
      //     icon: "fas fa-chart-bar",
      //     color: "lightBlue",
      //     text: "Successful entrepreneur",
      //   },
      //   { icon: "fas fa-atom", color: "emerald", text: "Problem solver" },
      //   { icon: "fas fa-running", color: "red", text: "Full throttle ethos" },
      // ],
      link: "https://www.linkedin.com/in/christophercormack",
      // dropdown: {
      //   icon: "fas fa-cog",
      //   button: { color: "white" },
      //   items: [
      //     { href: "#pablo", children: "Edit Profile" },
      //     { href: "#pablo", children: "Settings" },
      //     { href: "#pablo", children: "Log out" },
      //   ],
      // },    
    },
    {
      image: "images/allie_web.jpg",
      title: "Allie Sharma",
      description: "Venture Advisor, Health & Social Impact",
      affiliation: "Psychiatrist & Health Strategist",
      // list: [
      //   {
      //     icon: "fas fa-chart-bar",
      //     color: "lightBlue",
      //     text: "Successful entrepreneur",
      //   },
      //   { icon: "fas fa-atom", color: "emerald", text: "Problem solver" },
      //   { icon: "fas fa-running", color: "red", text: "Full throttle ethos" },
      // ],
      link: "https://www.linkedin.com/in/dralliesharmapsychiatry",
      // dropdown: {
      //   icon: "fas fa-cog",
      //   button: { color: "white" },
      //   items: [
      //     { href: "#pablo", children: "Edit Profile" },
      //     { href: "#pablo", children: "Settings" },
      //     { href: "#pablo", children: "Log out" },
      //   ],
      // },    
    },
  ],
  color: "black",
}




let Controller

class TeamView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../Team')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = TeamView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '6242288191da2b50c6937890'
    htmlEl.dataset['wfSite'] = '61e48a462079c356c6452042'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })

      return active.isAsync ? next : loading
    }))
  }

  render() {
    // const proxies = TeamView.Controller !== TeamView ? transformProxies(this.props.children) : {

    // }

    const buttonScrollToWhoSectionProps = {
      link:"team#who",
      webflowanimate:false,
    }

    const buttonScrollToTopSectionProps = {
      link:"team#top",
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/altalurra.webflow.css);
        ` }} />
        <span className="af-view">
          <div>
            <div className="af-class-page-wrapper">
              <SiteHeader />
              <div id="team" className="af-class-section af-class-home-hero af-class-wf-section bg-[url('../images/Background_Mountain_Masked.jpg')] bg-cover animate-xappear min-h-[100vh] content-center items-center place-content-center">
                <div className="af-class-container-default w-container">
                  <div>
                    <div className="af-class-mg-bottom-large-72px mb-0">
                      <div className="af-class-title-wrapper af-class-mg-bottom-24px">
                        <h2 className="af-class-display-2 inline text-altalurrablue-normal font-bold text-center animate-xappearfadein">Meet the Team</h2>                
                        <div className="af-class-inline af-class-underline-bg af-class-mg-bottom-mb-32px animate-xappearxfadein">
                          <div className="af-class-fill-block af-class-bg-neutral-100 bg-altalurrablue-normal opacity-50" />
                        </div> 
                      </div> 
                    </div>
                  </div>
                </div>
                <div className="af-class-container-default max-w-[90vw] animate-xappearfadein overscroll-y-auto">
                  <MultipleProfilesWrapper {...profilesProps} />
                </div>
                <div className="af-class-container-default max-w-[90vw] animate-xappearfadein overscroll-y-auto">
                  <MultipleProfilesWrapper {...profilesAdvisorsProps} />
                </div>
                <ScrollSectionButton {...buttonScrollToWhoSectionProps} />
              </div>
              <div id="who" className="af-class-section af-class-bg---90vh af-class-contact af-class-wf-section bg-[url('../images/Background_Mountain_Masked.jpg')] bg-cover min-h-[100vh] content-center items-center place-content-center">
                <div className="af-class-container-3 w-container content-center items-center place-content-center">
                  <h2 data-w-id="3eb5ca53-81ff-7c0d-f71c-03856ef027cb" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} className="af-class-display-2 af-class-mg-bottom-0px af-class-titleondark text-center">Our Ethos</h2>                
                  <div data-w-id="3eb5ca53-81ff-7c0d-f71c-03856ef027cb" className="mt-8" >
                    <TogetherContent />
                  </div>
                </div>
                <ScrollTopButton {...buttonScrollToTopSectionProps} />
              </div>
              <SiteFooter />
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default TeamView

/* eslint-enable */