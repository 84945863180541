import React from "react";
import PropTypes from "prop-types";

export default function CardProfileFull({
  image,
  title,
  description,
  affiliation,
  list,
  link,
}) {
  let colorClass = {
    blueGray: "text-blueGray-500 bg-blueGray-200",
    red: "text-red-500 bg-red-200",
    orange: "text-orange-500 bg-orange-200",
    amber: "text-amber-500 bg-amber-200",
    emerald: "text-emerald-500 bg-emerald-200",
    teal: "text-teal-500 bg-teal-200",
    lightBlue: "text-lightBlue-500 bg-lightBlue-200",
    indigo: "text-indigo-500 bg-indigo-200",
    purple: "text-purple-500 bg-purple-200",
    pink: "text-pink-500 bg-pink-200",
  };
  return (
    <>
      <div className="shadow-lg rounded-lg relative flex flex-col min-w-0 break-words bg-white w-full mb-6">
        <div className="relative">
          <img
            alt="..."
            className="w-full flex-shrink-0 rounded-t-lg"
            src={image}
          />
          <svg
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 583 95"
            className="block w-full absolute text-white svg-bg"
            style={{ height: "95px", bottom: "0" }}
          >
            <polygon
              points="583,55 583,95 0,95"
              style={{ fill: "currentColor" }}
            ></polygon>
          </svg>
        </div>
        <div className="py-5 flex flex-row items-center justify-items-center justify-around content-center w-full gap-x-2 pb-8">
          <div className="flex-initial contents-center pl-2">
            <h3 className="text-lg md:text-xl lg:text-2xl font-bold">{title}</h3>
            <p className="text-xs 2xl:text-sm text-blueGray-400 font-bold uppercase mb-0 whitespace-pre">
              {description}
            </p>
            <p className="text-xs 2xl:text-sm font-bold uppercase mb-0 whitespace-pre">
              {affiliation}
            </p>
          </div>
          <div className="flex-none bg-transparent rounded-b text-center h-full justify-end pr-2">
            <a 
              className={"inline-block outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out" + 
                " uppercase border border-solid font-bold" +
                " text-white bg-linkedin-regular border-linkedin-regular active:bg-linkedin-active active:border-linkedin-active" +
                " text-sm px-0 py-0 shadow hover:shadow-2xl rounded-md"}
              href={link}
              target="_blank"
            >
              <i class="fa-brands fa-linkedin-in text-lg md:text-xl lg:text-2xl align-middle px-2"></i>             
              {/* {"Connect"} */}
            </a>
          </div>
          {/* <div className="block w-full" style={{ overflowX: "auto" }}>
            <ul className="list-none mb-6">
              {list.map((prop, key) => {
                return (
                  <li className="py-1" key={key}>
                    <div className="flex items-center">
                      <span
                        className={
                          "inline-flex text-center rounded-full mr-3 items-center justify-center w-8 h-8 text-sm " +
                          colorClass[prop.color]
                        }
                      >
                        <i className={prop.icon}></i>
                      </span>
                      <h6 className="text-md text-blueGray-500">{prop.text}</h6>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div> */}
          {/* <div className="w-full bg-transparent rounded-b px-4 pt-6 border-t border-blueGray-200 text-center">
            <a 
              className={"inline-block outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out" + 
                " uppercase border border-solid font-bold last:mr-0 mr-2" +
                " text-white bg-linkedin-regular border-linkedin-regular active:bg-linkedin-active active:border-linkedin-active" +
                " text-sm px-6 py-2 shadow hover:shadow-2xl rounded-md"}
              href={link}
              target="_blank"
            >
              <i class="fa-brands fa-linkedin-in text-2xl align-middle px-6"></i>             
            </a>
          </div> */}
        </div>
      </div>
    </>
  );
}
CardProfileFull.defaultProps = {
  list: [],
};
CardProfileFull.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      text: PropTypes.string,
      color: PropTypes.oneOf([
        "blueGray",
        "red",
        "orange",
        "amber",
        "emerald",
        "teal",
        "lightBlue",
        "indigo",
        "purple",
        "pink",
      ]),
    }),
  ),
  link: PropTypes.string,
};
