import React from "react";
import {Helmet} from "react-helmet";
import ImpactView from "./webflow/ImpactView";

function Impact(props) {
    return (
        <>
            <Helmet>
                <title>Altalurra Impact Framework - Purpose and Growth</title>
                <meta content="We help Entrepreneurs build Impact-Driven Technology Companies." name="description"/>
                <meta content="Altalurra Impact Framework - Purpose and Growth" property="og:title"/>
                <meta content="We help Entrepreneurs build Impact-Driven Technology Companies." property="og:description"/>
                <meta content="https://altalurra-public.s3.amazonaws.com/altalurra-opengraph.png" property="og:image"/>
                <meta content="Altalurra Impact Framework - Purpose and Growth" property="twitter:title"/>
                <meta content="We help Entrepreneurs build Impact-Driven Technology Companies." property="twitter:description"/>
                <meta content="https://altalurra-public.s3.amazonaws.com/altalurra-opengraph.png" property="twitter:image"/>
                <meta property="og:type" content="website"/>
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>
            <ImpactView>
                {/* <myform {...props} /> */}
            </ImpactView>
        </>
    );
}

export default Impact;