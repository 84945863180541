import React from "react";
import {Helmet} from "react-helmet";
import ConnectView from "./webflow/ConnectView";
//import ContactForm from "../views/ContactFormView";
//import ContactFormController from "../components/TypeFormConnect";

function ConnectController(props) {
    return (
        <>
            <Helmet>
                <title>Get in touch with ALTALURRA Ventures</title>
                <meta content="We help Entrepreneurs build Impact-Driven Technology Companies." name="description"/>
                <meta content="Get in touch with ALTALURRA Ventures" property="og:title"/>
                <meta content="We help Entrepreneurs build Impact-Driven Technology Companies." property="og:description"/>
                <meta content="https://altalurra-public.s3.amazonaws.com/altalurra-opengraph.png" property="og:image"/>
                <meta content="Get in touch with ALTALURRA Ventures" property="twitter:title"/>
                <meta content="We help Entrepreneurs build Impact-Driven Technology Companies." property="twitter:description"/>
                <meta content="https://altalurra-public.s3.amazonaws.com/altalurra-opengraph.png" property="twitter:image"/>
                <meta property="og:type" content="website"/>
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>
            <ConnectView>
                {/* <ContactFormController {...props} /> */}
            </ConnectView>
        </>
    );
}

export default ConnectController;