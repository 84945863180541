import React from 'react'
import { createScope, map, transformProxies } from './helpers'
import ButtonFullEffects from '../../components/webflow/ButtonFullEffects'
import ScrollSectionButton from '../../components/webflow/ScrollSectionButton'
import ScrollTopButton from '../../components/ScrollTopButton'
import SiteHeader from '../../components/SiteHeader'
import SiteFooter from '../../components/SiteFooter'
import Collapsible from 'react-collapsible'

const scripts = [
  { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=61e48a462079c356c6452042").then(body => body.text()), isAsync: false },
  { loading: fetch("js/webflow.js").then(body => body.text()), isAsync: false },
]

let Controller

class ImpactView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../Impact')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = ImpactView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '6242288191da2b50c6937890'
    htmlEl.dataset['wfSite'] = '61e48a462079c356c6452042'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })

      return active.isAsync ? next : loading
    }))

   }
  

  render() {
    // const proxies = ImpactView.Controller !== ImpactView ? transformProxies(this.props.children) : {

    // }
    const buttonBindingProps = {
      image:"pinch-easy-white.png",
      displayText:"Impact measurement",
      link: "/impact#binding",
    }
    const buttonValueProps = {
      image:"ideas-white3x.png",
      displayText:"Impact Value Added (IVA)",
      link: "/impact#value",
    }
    // const buttonDrivingProps = {
    //   image:"ideas-white3x.png",
    //   displayText:"Driving through an Impact Lens",
    //   link: "/impact#driving",
    // }

    const buttonScrollToBindingSectionProps = {
      link:"impact#binding",
      webflowanimate:false,
    }

    const buttonScrollToValueSectionProps = {
      link:"impact#value",
    }

    const buttonScrollToTopSectionProps = {
      link:"impact#top",
    }

    return (
      <span >
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/altalurra.webflow.css);
        ` }} />
        <span className="af-view">
          <div>
            <div className="af-class-page-wrapper">
              <SiteHeader />
              <div className="af-class-section af-class-home-hero af-class-wf-section  bg-[url('../images/Background_PlanetAndAtmosphere_Masked.jpg')] bg-cover mt-[3px] animate-xappear">
                <div className="af-class-container-default w-container">
                  <div>
                    <div className="af-class-mg-bottom-large-72px">
                      <div className="af-class-title-wrapper af-class-mg-bottom-24px">
                        <h2 className="af-class-display-2 inline text-altalurrablue-normal font-bold text-center animate-xappearfadein">The Altalurra Impact Framework<br/>unites Purpose and Growth</h2>                
                        <div className="af-class-inline af-class-underline-bg af-class-mg-bottom-mb-32px animate-xappearxfadein">
                          <div className="af-class-fill-block af-class-bg-neutral-100 bg-altalurrablue-normal opacity-50" />
                        </div> 
                      </div> 

                      <p className="af-class-paragraph-large af-class-home-hero-paragraph animate-xappearfadein">
                        The Altalurra Impact Framework is designed to optimize for revenue, margins and impact. It is a data-driven, quantitative framework that includes state-of-the-art impact measurement and a core set of guiding principles which jointly align impact and financial objectives. Practical metrics along each objective are defined so as to be used in real-time and can be framed into monetary terms to help founders make coherent business decisions. Startups benefit best from our unique approach to impact when combined in the field with our partnership and operating framework:
                      </p>
                      <div className="af-class-paragraph-large text-white flex flex-cols flex-wrap gap-y-4 mb-4 animate-xappearfadein">
                        <div className="flex w-full flex-col lg:flex-row gap-x-1 gap-y-1 group">                              
                          <div className="flex-none w-[300px]"><ul className="list-disc list-inside p-0 m-0 space-0"><li><span className="font-bold group-hover:text-altalurrablue-normal text-left duration-200">Quantifiable steps</span></li></ul></div>
                          <div>We establish together with founders clear baselines and targets from Day 1, as well as tailored and transparent measurements and evaluations</div>
                        </div>
                        <div className="flex w-full flex-col lg:flex-row gap-x-1 gap-y-1 group">                              
                          <div className="flex-none w-[300px]"><ul className="list-disc list-inside p-0 m-0 space-0"><li><span className="font-bold group-hover:text-altalurrablue-normal text-left duration-200">Fast-cycle iterations</span></li></ul></div>
                          <div>We assist founders in order to leverage constant feedback loops through low cost, low risk, small-scale pilots, incorporating learnings back into the impact and growth models</div>
                        </div>
                        <div className="flex w-full flex-col lg:flex-row gap-x-1 gap-y-1 group">                              
                          <div className="flex-none w-[300px]"><ul className="list-disc list-inside p-0 m-0 space-0"><li><span className="font-bold group-hover:text-altalurrablue-normal text-left duration-200">Expert validation</span></li></ul></div>
                          <div>Tapping into our robust network built over 40 combined years in the tech/impact space, we bring together different specialised experts to identify hurdles and to increase alignment of impact and growth</div>
                        </div>
                      </div>
                      <p className="af-class-paragraph-large af-class-home-hero-paragraph pt-4 font-bold hover:text-altalurrablue-normal animate-xappearfadein duration-200">
                        Through the journey, Altalurra helps founders define the optimized model that balances purpose and growth to let them focus on their mission.
                      </p>
                    </div>
                    <div className="af-class-_2-buttons-wrap flex-col md:flex-row gap-x-8 gap-y-3 animate-xappearfadein">
                      <ButtonFullEffects {...buttonBindingProps} />
                      <ButtonFullEffects {...buttonValueProps} />
                    </div>
                  </div>
                </div>
                <ScrollSectionButton {...buttonScrollToBindingSectionProps} />
              </div>
              <div id="binding" className="af-class-section af-class-wf-section min-h-[100vh] content-center items-center place-content-center">
                <div className="af-class-container-default w-container content-center items-center place-content-center">
                  <div>
                    <div className="af-class-_2-column-grid af-class-larger-right-column self-center place-self-center">
                      <div>
                        <div data-w-id="9154ae7b-ed5f-a495-270d-69a8eef70c82" className="af-class-dash---130px af-class-mg-bottom-32px">
                          <div className="af-class-fill-block af-class-bg-primary-1 bg-altalurrablue-normal opacity-50" />
                        </div>
                        <h2 data-w-id="3eb5ca53-81ff-7c0d-f71c-03856ef027cb" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} className="af-class-display-2 af-class-mg-bottom-0px af-class-titleonwhite">
                          360&deg; Impact Drivers
                        </h2>
                      </div>
                      <div className="af-class-div-block-2 af-class-color-neutral-800 content-center">
                        <p data-w-id="f4e69ca9-4bf5-636d-0b9c-3702a8342414" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} className="af-class-paragraph-large">
                            Startups are empowered to innovate in their solutions and designs as we ensure that impact is contextualized, holistic and business practical. Factors we take into account                        
                        </p>
                        <div className="af-class-paragraph-large flex flex-cols flex-wrap gap-y-4 mb-4"
                          data-w-id="f4e69ca9-4bf5-636d-0b9c-3702a8342414" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}}>
                          <div className="flex w-full flex-col lg:flex-row gap-x-1 gap-y-1 group hover:scale-105 duration-300">                              
                            <div className="flex-none w-[150px]"><ul className="list-disc list-inside p-0 m-0 space-0"><li><span className="font-bold group-hover:text-altalurrablue-normal text-left">
                              Context</span></li></ul></div>
                            <div>1) the stage of the company, 2) the current and in-plan future scope, and 3) the companies' position in the value chain from raw resources/energy to global stakeholder </div>
                          </div>
                          <div className="flex w-full flex-col lg:flex-row gap-x-1 gap-y-1 group hover:scale-105 duration-300">                              
                            <div className="flex-none w-[150px]"><ul className="list-disc list-inside p-0 m-0 space-0"><li><span className="font-bold group-hover:text-altalurrablue-normal text-left">
                              What</span></li></ul></div>
                            <div>Impact dimensions are selected per business activity (product, solution, services), then aggregated and weighted through internal ESG practices; multiple angles considered (e.g.&nbsp; 
                              <a href="https://sdgs.un.org/goals" target="_blank" rel="noreferrer" className="af-class-link-block w-inline-block align-text-bottom mx-0">
                                <img src="images/sustainable-development-goals.png" loading="eager" width={200} alt="Sustainable Development Goals" />
                              </a>
                              ,&nbsp;
                              <a href="https://impactmanagementproject.com/" target="_blank" rel="noreferrer" className="af-class-link-block w-inline-block align-bottom mx-0">
                                <img src="images/imp.png" loading="eager" width={100} alt="Impact Management Project" />
                              </a>
                              )</div>
                          </div>
                          <div className="flex w-full flex-col lg:flex-row gap-x-1 gap-y-1 group hover:scale-105 duration-300">                              
                            <div className="flex-none w-[150px]"><ul className="list-disc list-inside p-0 m-0 space-0"><li><span className="font-bold group-hover:text-altalurrablue-normal text-left">
                              Who</span></li></ul></div>
                            <div>We consider homogenous groups of stakeholders, per product; homogeneity might include geographical, economical or any relevant characteristic aligning to business segments, as basis to quantify social and environmental impact values</div>
                          </div>
                          <div className="flex w-full flex-col lg:flex-row gap-x-1 gap-y-1 group hover:scale-105 duration-300">                              
                            <div className="flex-none w-[150px]"><ul className="list-disc list-inside p-0 m-0 space-0"><li><span className="font-bold group-hover:text-altalurrablue-normal text-left">
                              How</span></li></ul></div>
                            <div>First order impact drivers are determined across 1) sourced inputs and supply networks, 2) transformation processes/mechanisms, input wasted and energy consumed, and 3) outputs life-cycle (packaging, shipping, recycling, etc...) and social effects (health, education, inclusion, consumption, etc...) </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p data-w-id="f4e69ca9-4bf5-636d-0b9c-3702a8342414" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} className="af-class-paragraph-large">
                          Altalurra leverages guiding principles of the&nbsp;
                          <a href="https://thegiin.org" target="_blank" rel="noreferrer" className="af-class-link-block w-inline-block align-text-bottom">
                            <img src="images/giin_logo_tagline.svg" loading="eager" width={60} alt="GIIN" />
                          </a>
                          network as a starting point, uses its own expertise and bring in break-through partners in impact measurement to keep an edge on regulations (announcements upcoming).
                        </p>
                  </div>
                </div>
                <ScrollSectionButton {...buttonScrollToValueSectionProps} />
              </div>
              <div id="value" className="af-class-section af-class-bg---90vh af-class-contact af-class-wf-section  min-h-[100vh] bg-[url('../images/Background_ConnectedPlanet_Masked.jpg')] bg-cover min-h-[100vh] content-center items-center place-content-center">
                <div className="af-class-container-3 w-container content-center items-center place-content-center">
                  <h2 data-w-id="f4e69ca9-4bf5-636d-0b9c-3702a8342414" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} className="af-class-display-2 af-class-mg-bottom-0px af-class-titleondark text-center">
                    Impact Value Added (IVA)
                  </h2>    
                  <p data-w-id="f4e69ca9-4bf5-636d-0b9c-3702a8342414" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} className="af-class-paragraph-large text-white mt-12">
                    <span className="font-bold text-altalurrablue-normal text-left">
                      From non-actionable metrics        
                    </span>
                  </p>    
                  <p data-w-id="f4e69ca9-4bf5-636d-0b9c-3702a8342414" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} className="af-class-paragraph-large text-white mt-12">
                    First order 360&deg; impact drivers, measured in real-time provide candidate metrics for setting up business-relevant KPIs. If raw metrics on - for instance - tons of emissions are useful indicators, they fall short of contextual information which would enable to integrate them into decision-making processes. Indeed, business practices require founders and investors to make decisions according to returns on time, resources and ultimately investment. 
                  </p>
                  <p data-w-id="f4e69ca9-4bf5-636d-0b9c-3702a8342414" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} className="af-class-paragraph-large text-white mt-12">
                    <span className="font-bold text-altalurrablue-normal text-left">
                      To measure impact in monetary terms        
                    </span>
                  </p>    
                  <p data-w-id="f4e69ca9-4bf5-636d-0b9c-3702a8342414" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} className="af-class-paragraph-large text-white mt-12">
                    We empower founders and investors through an impact valuation adjustment framework which quantifies impact value through relative effects of drivers on the companies' ecosystem. A net positive impact value defines the Impact Value Added of potential decisions. Going one step further, once activated by the company, the IVA aggregates into a monetary premium on top of the otherwise valuation of a similar company with neutral impact when compared to peers. While the IVA model will be refined as the industry evolves and regulators weight in, we strongly believe that the IVA concept enables a convergence towards sustainable businesses while constantly integrating improvements in impact measurement capabilities.
                  </p>
                </div>
                <ScrollTopButton {...buttonScrollToTopSectionProps} />
              </div>
              <div id="driving" className="af-class-section af-class-bg-neutral-100 af-class-wf-section hidden">
                <div className="af-class-container-default w-container">
                  <div>
                    <div className="af-class-_2-column-grid af-class-larger-left-column">
                      <div id="w-node-_706af8c8-eaf5-a80a-b225-8c32b67cdc09-c6937890" className="af-class-inner-container-80 af-class-_100-in-mobile">
                        <div className="af-class-card af-class-sticky-card">
                          <div className="af-class-dash---130px af-class-mg-bottom-32px">
                            <div className="af-class-fill-block af-class-bg-primary-1" />
                          </div>
                          <h2 data-w-id="706af8c8-eaf5-a80a-b225-8c32b67cdc0d" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} className="af-class-display-2 text-altalurragreen-normal">
                            Driving through an Impact Lens
                          </h2>
                          <p data-w-id="706af8c8-eaf5-a80a-b225-8c32b67cdc0f" className="af-class-paragraph-large mt-20" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}}>
                            Impact entails a deep understanding of your business and its ecosystem. It requires practical metrics tailor-made to your products and business model, beyond information provided by standardized frameworks. <br /><br /> All the way through our relationship, we work with you and our impact specialist partners for analyzing your impacts, evolving measures and objectives as your business develops, assisting your decision-making process, and producing impact reports for your clients, your investors and ours.
                          </p>                        
                        </div>
                      </div>
                      <div>
                        <div className="af-class-card af-class-invested-industries af-class-first">
                          {/* <h3 className="inline text-altalurragreen-normal">
                            <i className="inline-flex text-white bg-altalurragreen-normal rounded-full fa-solid fa-1 flex self-start align-start items-center justify-center w-10 h-10 mr-4" />                          
                            Initial Assessment
                          </h3>
                          <p className="mt-4">
                            During our initial discussions and through our investment process, we translate your mission and impact into tailored Practical Business Objectives.
                          </p> */}
                          <Collapsible 
                            containerElementProps={{ 'data-w-id':"3eb5ca53-81ff-7c0d-f71c-03856ef027cb"}} 
                            className="" 
                            transitionTime={300}
                            contentContainerTagName="p"  
                            trigger={<><i class="animate-custompulse inline-flex text-white bg-altalurragreen-normal rounded-full fa-solid fa-1 flex self-start align-start items-center justify-center w-10 h-10"></i><div className='ml-4 inline'>Initial Assessment</div></>} 
                            triggerWhenOpen={<><i class="inline-flex text-white bg-altalurragreen-normal rounded-full fa-solid fa-1 flex self-start align-start items-center justify-center w-10 h-10"></i><div className='ml-4 inline'>Initial Assessment</div></>}
                            triggerClassName="af-class-paragraph-xlarge af-class-text-highlight" 
                            triggerOpenedClassName="af-class-paragraph-xlarge af-class-text-highlight"
                          >                        
                            <p className="af-class-paragraph-large mt-4 mb-2">
                              During our initial discussions and through our investment process, using the&nbsp; 
                              <a href="https://thegiin.org/tools/" target="_blank" rel="noreferrer" className="af-class-link-block w-inline-block align-text-bottom">
                                <img src="images/IRIS-Plus-2Color.png" loading="eager" width={60} alt="GIIN IRIS+" />
                              </a>
                              framework and proprietary approaches from our partners as starting points, we translate your mission and impact into tailored practical business objectives.
                            </p>
                          </Collapsible>
                        </div>
                        <div className="af-class-card af-class-invested-industries">
                          <Collapsible 
                            containerElementProps={{ 'data-w-id':"3eb5ca53-81ff-7c0d-f71c-03856ef027cb"}} 
                            className="" 
                            transitionTime={300}
                            contentContainerTagName="p"  
                            trigger={<><i class="animate-custompulse inline-flex text-white bg-altalurragreen-normal rounded-full fa-solid fa-2 flex self-start align-start items-center justify-center w-10 h-10"></i><div className='ml-4 inline'>Operational Strategy Guidance</div></>} 
                            triggerWhenOpen={<><i class="inline-flex text-white bg-altalurragreen-normal rounded-full fa-solid fa-2 flex self-start align-start items-center justify-center w-10 h-10"></i><div className='ml-4 inline'>Operational Strategy Guidance</div></>}
                            triggerClassName="af-class-paragraph-xlarge af-class-text-highlight" 
                            triggerOpenedClassName="af-class-paragraph-xlarge af-class-text-highlight"
                          >                        
                            <p className="af-class-paragraph-large mt-4 mb-2">
                            Impact measures are not used as a sporadic reporting input, but in real-time for decision support in a similar fashion as standard financial KPIs and metrics, informing decisions.
                            </p>
                          </Collapsible>
                        </div>
                        <div className="af-class-card af-class-invested-industries">
                          <Collapsible 
                            containerElementProps={{ 'data-w-id':"3eb5ca53-81ff-7c0d-f71c-03856ef027cb"}} 
                            className="" 
                            transitionTime={300}
                            contentContainerTagName="p"  
                            trigger={<><i class="animate-custompulse inline-flex text-white bg-altalurragreen-normal rounded-full fa-solid fa-3 flex self-start align-start items-center justify-center w-10 h-10"></i><div className='ml-4 inline'>Tracking and Reporting</div></>} 
                            triggerWhenOpen={<><i class="inline-flex text-white bg-altalurragreen-normal rounded-full fa-solid fa-3 flex self-start align-start items-center justify-center w-10 h-10"></i><div className='ml-4 inline'>Tracking and Reporting</div></>}
                            triggerClassName="af-class-paragraph-xlarge af-class-text-highlight" 
                            triggerOpenedClassName="af-class-paragraph-xlarge af-class-text-highlight"
                          >                        
                            <p className="af-class-paragraph-large mt-4 mb-2">
                            We help you collect required data and KPIs and run them through our partners' systems so as to assess together progress and generate best-in-class reports fitting frameworks such as UN SDGs, as defined by the IMP, and according to the Life Cycle Analysis approach.
                            </p>
                          </Collapsible>
                        </div>
                        <div className="af-class-card af-class-invested-industries af-class-last">
                          <Collapsible 
                            containerElementProps={{ 'data-w-id':"3eb5ca53-81ff-7c0d-f71c-03856ef027cb"}} 
                            className="" 
                            transitionTime={300}
                            contentContainerTagName="p"  
                            trigger={<><i class="animate-custompulse inline-flex text-white bg-altalurragreen-normal rounded-full fa-solid fa-4 flex self-start align-start items-center justify-center w-10 h-10"></i><div className='ml-4 inline'>Investment Support</div></>} 
                            triggerWhenOpen={<><i class="inline-flex text-white bg-altalurragreen-normal rounded-full fa-solid fa-4 flex self-start align-start items-center justify-center w-10 h-10"></i><div className='ml-4 inline'>Investment Support</div></>}
                            triggerClassName="af-class-paragraph-xlarge af-class-text-highlight" 
                            triggerOpenedClassName="af-class-paragraph-xlarge af-class-text-highlight"
                          >                        
                            <p className="af-class-paragraph-large mt-4 mb-2">
                              Impact metrics and progress towards net positive impact are an integral part of our decisions to follow up on subsequent financing needs, as much as traditional performance metrics are. We further help you demonstrate to new potential investors or strategic parties your value-added thanks to your impact.
                            </p>
                          </Collapsible>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <SiteFooter />
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default ImpactView