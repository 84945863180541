import React from 'react'
import PropTypes from "prop-types";

export default function SiteHeader({displayHome}) {
  return (
    <span className="">
      <style dangerouslySetInnerHTML={{ __html: `
        @import url(/css/normalize.css);
        @import url(/css/webflow.css);
        @import url(/css/altalurra.webflow.css);
      ` }} />
      <span className="af-view af-class-page-wrapper">
        <div data-collapse="medium" data-animation="default" data-duration={400} data-w-id="04824156-eced-c654-ab98-88caa897ff70" data-easing="ease" data-easing2="ease" role="banner" className="af-class-header w-nav fixed animate-xheader">
          <div className="af-class-container-default w-container">
            <div className="af-class-header-wrapper">
              <div className="af-class-split-content af-class-header-right">
                <a href="index" aria-current="page" className="af-class-header-link w-nav-brand w--current"><img src="images/logo-bg-transparent.png" sizes="(max-width: 479px) 100vw, 226px" srcSet="images/logo-bg-transparent-p-500.png 500w, images/logo-bg-transparent.png 1241w" alt="Altalurra Ventures" className="af-class-header-logo" /></a>
             </div>
              <div className="af-class-split-content af-class-header-left">
                <nav role="navigation" className="af-class-nav-menu w-nav-menu">
                  <ul className="af-class-header-navigation max-w-[60vw] text-[1.3vw] xl:text-base">
                    {(displayHome === true) && (<li className="af-class-nav-item-wrapper">
                      <a href="index" aria-current="page" className="af-class-nav-link w--current">Home</a>
                    </li>)}
                    <li className="af-class-nav-item-wrapper">
                      <a href="manifesto" aria-current="page" className="af-class-nav-link w--current">Manifesto</a>
                    </li>
                    <li className="af-class-nav-item-wrapper">
                      <a href="strategy" aria-current="page" className="af-class-nav-link w--current">Approach</a>
                    </li>
                    <li className="af-class-nav-item-wrapper">
                      <a href="impact" aria-current="page" className="af-class-nav-link w--current">Impact</a>
                    </li>
                    <li className="af-class-nav-item-wrapper">
                      <a href="news" aria-current="page" className="af-class-nav-link w--current">News</a>
                    </li>
                    <li className="af-class-nav-item-wrapper">
                      <a href="team" aria-current="page" className="af-class-nav-link w--current">Team</a>
                    </li>
                    <li className="af-class-nav-item-wrapper animate-custompulse text-altalurrablue-normal">
                      <a href="connect" className="af-class-nav-link text-altalurrablue-normal font-bold">Connect</a>
                    </li>
                    {/* <li className="af-class-nav-item-wrapper">
                      <div data-hover="true" data-delay={0} data-w-id="04824156-eced-c654-ab98-88caa897ff80" className="af-class-nav-link w-dropdown">
                        <div className="af-class-nav-link af-class-dropdown w-dropdown-toggle">
                          <div className="af-class-mg-right-8px">...</div>
                          <div className="af-class-dropdown-arrow"></div>
                        </div>
                        <nav className="af-class-dropdown-container w-dropdown-list bg-altalurradark-webflow">
                          <div className="af-class-dropdown-main-wrapper bg-transparent text-white">
                            <div className="af-class-dropdown-column">
                              <div className="af-class-menu-title">Pages</div>
                              <div className="af-class-dropdown-nav-wrapper">
                                <ul className="af-class-nav-list af-class-first w-list-unstyled">
                                  <li className="af-class-nav-list-item">
                                    <a href="manifesto" className="af-class-nav-dropdown-link w-dropdown-link">Manifesto</a>
                                  </li>
                                  <li className="af-class-nav-list-item">
                                    <a href="strategy" className="af-class-nav-dropdown-link w-dropdown-link">Approach</a>
                                  </li>
                                  <li className="af-class-nav-list-item">
                                    <a href="impact" className="af-class-nav-dropdown-link w-dropdown-link">Impact</a>
                                  </li>
                                  <li className="af-class-nav-list-item">
                                    <a href="news" className="af-class-nav-dropdown-link w-dropdown-link">News</a>
                                  </li>
                                  <li className="af-class-nav-list-item">
                                    <a href="team" className="af-class-nav-dropdown-link w-dropdown-link">Team</a>
                                  </li>
                                  <li className="af-class-nav-list-item">
                                    <a href="connect" className="af-class-nav-dropdown-link w-dropdown-link">Connect</a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </nav>
                      </div>
                    </li> */}
                  </ul>
                </nav>
                <div className="af-class-menu-button w-nav-button">
                  <div className="af-class-menu-button-icon-main-wrap">
                    <div className="af-class-menu-button-icon-wrap">
                      <div className="af-class-menu-button-icon-line af-class-top" />
                      <div className="af-class-menu-button-icon-line af-class-middle" />
                      <div className="af-class-menu-button-icon-line af-class-bottom" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </span>
    </span>
  )

}

SiteHeader.defaultProps = {
  displayHome: true
};

SiteHeader.propTypes = {
  displayHome: PropTypes.bool
};